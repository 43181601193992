import React, {Component} from "react";
import {
    BorderBox1,
    Decoration5,
    BorderBox8,
    BorderBox2,
    BorderBox13,
    BorderBox11,
    ConicalColumnChart,
    Decoration7,
    Charts,
    DigitalFlop
} from "@jiaminghi/data-view-react";
import "./exampleBigNumber.scss"

export default class ExampleBigNumber extends Component {
    render() {
        const config1 = {
            number: [10000],
            content: '{nt}',
            style: {fontSize: 20}
        }

        return (

            <BorderBox13>
                <div className="bigContainer">
                    <DigitalFlop config={config1} />
                    <div className="bigName">校本资源</div>
                </div>
            </BorderBox13>
        )
    }
}
