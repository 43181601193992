// 教师教学有效性分析
import React, {Component} from "react";
import {BorderBox7} from "@jiaminghi/data-view-react";
import http from "../../../http";
import Charts from "@jiaminghi/data-view-react/es/charts";

export default class TeacherLearningValid extends Component {
    constructor(props) {
        super(props);

        this.refreshIntervalSeconds = this.$config.refreshIntervalSeconds.teacherGrowth || 9

        this.state = {
            title: '',
            option: null
        }
    }

    componentDidMount() {
        http.get('/api/big_teacher_growth/teacherLearningValid', {params: {force_update: 1}})
            .then((res) => {
                if (res.data.result === 0)
                    return

                let jobs = []
                for (let gradeIndex=0; gradeIndex<res.data.grades.length; gradeIndex++) {

                    const subjects = ['语文', '数学', '英语', '物理', '化学', '政治', '生物', '历史', '地理'];
                    subjects.forEach((subject) => {
                        jobs.push({
                            gradeIndex: gradeIndex,
                            gradeName: res.data.grades[gradeIndex].gradeName,
                            subject: subject
                        })
                    })

                }

                this.grades = res.data.grades;
                this.jobs = jobs;
                this.jobIndex = 0
                this.timerId && clearInterval(this.timerId)
                this.tick()
                this.timerId = setInterval(() => {
                    this.tick()
                }, this.refreshIntervalSeconds * 1000)
            })
    }

    tick() {
        let job = this.jobs[this.jobIndex]
        let title = job.gradeName + job.subject

        if (this.jobIndex >= this.jobs.length-1)
            this.jobIndex = 0
        else
            this.jobIndex ++

        const classes = [];
        let validData = []
        let evaluatedData = []
        this.grades[job.gradeIndex].classes.forEach((item) => {
            if (classes.indexOf(item.classNo) < 0) {
                classes.push(item.classNo)
                validData.push(0)
                evaluatedData.push(0)
            }
        })

        let index = 0
        let validMin = 99999
        let validMax = 0
        let evaluatedMin = 99999
        let evaluatedMax = 0
        this.grades[job.gradeIndex].classes.forEach((item) => {
            if (item.subjectName !== job.subject)
                return

            validData[index] = item.validPercent
            evaluatedData[index] = item.evaluatedPercent

            validMin = Math.min(validMin, item.validPercent)
            validMax = Math.max(validMax, item.validPercent)

            evaluatedMin = Math.min(evaluatedMin, item.evaluatedPercent)
            evaluatedMax = Math.max(evaluatedMax, item.evaluatedPercent)

            index += 1
        })

        for (let i=0; i<validData.length; i++) {
            if (validMax > validMin)
                validData[i] = (validData[i] - validMin) / (validMax - validMin)  * 100
            if (evaluatedMax > evaluatedMin)
                evaluatedData[i] = (evaluatedData[i] - evaluatedMin) / (evaluatedMax - evaluatedMin)  * 100
        }

        let option = {
            title: {
                show: false
            },
            legend: {
                data: [
                    {
                        name: '反馈率',
                        color: '#aeeff0'
                    },
                    {
                        name: '有效率',
                        color: '#f1829f'
                    }
                ],
                top: '12%',
                right: '5%',
                textStyle: {
                    fill: '#999999'
                },
            },
            xAxis: {
                data: classes,
                axisLabel: {
                    style: {
                        fill: 'rgba(255,255,255,0.3)',
                        fontSize: 10,
                        rotate: 45
                    }
                }
            },
            yAxis: [
                {
                    name: '反馈率',
                    data: 'value',
                    min: 0,
                    max: 100,
                    splitLine: {
                        show: true,
                        style: {
                            stroke: 'rgba(255,255,255,0.1)',
                            lineWidth: 1
                        }
                    }
                },
                {
                    name: '有效率',
                    data: 'value',
                    position: 'right',
                    min: 0,
                    max: 100,
                    splitLine: {
                        show: false
                    }
                }
            ],
            grid: {
                left: '5%',
                top: '18%',
                right: '5%',
                bottom: '20%',
            },
            series: [
                {
                    name: '反馈率',
                    data: evaluatedData,
                    type: 'line',
                    smooth: false,
                    lineArea: {
                        show: true,
                        gradient: ['rgba(251, 114, 147, 1)', 'rgba(251, 114, 147, 0)']
                    },
                    lineStyle: {
                        stroke: 'rgba(251, 114, 147, 1)',
                        lineDash: [3, 3]
                    },
                    linePoint: {
                        style: {
                            stroke: 'rgba(251, 114, 147, 1)'
                        }
                    },
                    yAxisIndex: 1
                },
                {
                    name: '有效率',
                    data: validData,
                    type: 'bar',
                    gradient: {
                        color: ['rgba(103, 224, 227, .6)', 'rgba(103, 224, 227, .1)']
                    },
                    barStyle: {
                        stroke: 'rgba(103, 224, 227, 1)'
                    }
                }
            ]
        }

        this.setState({
            title: title,
            option: option
        })
    }

    render() {
        return (
            <BorderBox7>
                <div className="box-title">教师教学有效性分析 ({this.state.title})</div>
                {this.state.option ?
                    <Charts option={this.state.option}
                            style={{height: '100%', width: '100%'}} />
                    : '' }
            </BorderBox7>
        )
    }
}
