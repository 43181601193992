import React, {Component} from "react";
import {BorderBox12, Decoration10, ScrollBoard, Decoration5, DigitalFlop} from "@jiaminghi/data-view-react";
import "./index.scss"
import moment from "moment";
import http from "../../http";

function formatter(number) {
	return moment().format('HH:mm:ss')
}

export default class Title extends Component {
	constructor(props) {
		super(props);

		this.state = {
			now: '',
			termName: '',
			week: {},
			config: null,

			nowConfig: {
				number: [0],
				content: '{nt}',
				formatter
			}
		}
	}

	componentDidMount() {
		http.get('/api/big_title/taskSchedule')
			.then((res) => {

				let tasks = []
				res.data.tasks?.map((task) => {
					tasks.push([
						task.task_day,
						task.task_week_day,
						task.task_time,
						task.task_content,
						task.task_address,
						task.task_manager
					])
				})

				let config = {
					header: ['日期', '星期', '节次', '内容', '地点', '主持'],
					data: tasks,
					columnWidth: ['100', '100', '100', '500', '100', '100'],
					align: ['left', 'left', 'left', 'left', 'left', 'left']
				}

				this.setState({
					termName: res.data.termName,
					week: res.data.week,
					config: config
				})
			})
	}

	render() {
		return (
			<BorderBox12 color={['transparent', 'transparent']} backgroundColor='transparent'>
				<div className="page">
					<div className="page-body page-title">
						<div className="title-container" style={{overflow:"hidden"}}>

							<h1 style={{fontSize: 45, margin: '0px 0 10px 0', color: '#03c5fc'}}>株洲四中智慧校园数据中心</h1>
							<Decoration10 style={{width: '100%', height: '5px'}} />
							<h1 style={{
								fontSize: 20,
								margin: '10px 0 15px 0',
								color: '#adabab'
							}}>{this.state?.termName}第{this.state.week?.index}周</h1>

							<div className="inner-container">
								<ScrollBoard config={this.state.config}/>
							</div>

						</div>
					</div>
				</div>
			</BorderBox12>
		)
	}
}
