import React, {Component} from "react";
import {BorderBox7, Charts} from "@jiaminghi/data-view-react";
import http from "../../../http";

export default class AppraisalDepartGrade extends Component {
    constructor(props) {
        super(props);

        this.refreshIntervalSeconds = this.$config.refreshIntervalSeconds.appraisal || 9;

        this.labelOption = {
            show: true,
            style: {
                fill: '#e79205'
            }
        }

        this.state = {
            option: null
        }
    }

    fetchData(successCallback) {
        http.get('/api/big_appraisal/grade')
            .then((res) => {

                let categories = []
                let series0_data = []
                let series1_data = []
                let series2_data = []
                let series3_data = []

                let total_series0_data = []
                let total_series1_data = []
                let total_series2_data = []

                res.data.data.forEach((item) => {
                    categories.push(item.name)
                    series0_data.push(item.教室办公室管理)
                    series1_data.push(item.年级风貌)
                    series2_data.push(item.管理效率)
                    series3_data.push(item.管理团队)

                    total_series0_data.push(item.年级组考核得分)
                    total_series1_data.push(item.年级组工作得分)
                    total_series2_data.push(item.年级所有班级考核得分平均值)
                })

                this.optionDetail = {
                    title: {
                        show: false
                    },
                    legend: {
                        show: true,
                        top: '22%',
                        textStyle: {
                            fill: '#999999',
                            fontSize: '0.2rem'
                        },
                        data: ['教室办公室管理', '年级风貌', '管理效率', '管理团队']
                    },
                    xAxis: {
                        data: categories,
                            axisLabel: {
                            style: {
                                fill: 'rgba(255,255,255,0.3)',
                                    fontSize: 10,
                                    rotate: 45
                            }
                        }
                    },
                    yAxis: {
                        data: 'value',
                            splitLine: {
                            show: true,
                                style: {
                                stroke: 'rgba(255,255,255,0.1)',
                                    lineWidth: 1
                            }
                        }
                    },
                    grid: {
                        left: '5%',
                            top: '28%',
                            right: '5%',
                            bottom: '20%',
                            style: {
                            fill: 'rgba(255, 255, 255, 0.2)',
                        }
                    },
                    series: [
                        {
                            name: '教室办公室管理',
                            type: 'bar',
                            data: series0_data,
                            label: this.labelOption
                        },
                        {
                            name: '年级风貌',
                            type: 'bar',
                            data: series1_data,
                            label: this.labelOption
                        },
                        {
                            name: '管理效率',
                            type: 'bar',
                            data: series2_data,
                            label: this.labelOption
                        },
                        {
                            name: '管理团队',
                            type: 'bar',
                            data: series3_data,
                            label: this.labelOption
                        }
                    ]
                }

                this.optionTotal = {
                    title: {
                        show: false
                    },
                    legend: {
                        show: true,
                        top: '22%',
                        textStyle: {
                            fill: '#999999'
                        },
                        data: ['最终得分', '工作得分', '班级考核平均分']
                    },
                    xAxis: {
                        data: categories,
                        axisLabel: {
                            style: {
                                fill: 'rgba(255,255,255,0.3)',
                                fontSize: 10,
                                rotate: 45
                            }
                        }
                    },
                    yAxis: {
                        data: 'value',
                        splitLine: {
                            show: true,
                            style: {
                                stroke: 'rgba(255,255,255,0.1)',
                                lineWidth: 1
                            }
                        }
                    },
                    grid: {
                        left: '5%',
                        top: '28%',
                        right: '5%',
                        bottom: '20%',
                        style: {
                            fill: 'rgba(255, 255, 255, 0.2)',
                        }
                    },
                    series: [
                        {
                            name: '最终得分',
                            type: 'bar',
                            data: total_series0_data,
                            label: this.labelOption
                        },
                        {
                            name: '工作得分',
                            type: 'bar',
                            data: total_series1_data,
                            label: this.labelOption
                        },
                        {
                            name: '班级考核平均分',
                            type: 'bar',
                            data: total_series2_data,
                            label: this.labelOption
                        }
                    ]
                }

                this.optionIndex = 0
                successCallback()
            })
    }

    tick() {
        this.setState({
            option: (this.optionIndex === 0 ? this.optionDetail : this.optionTotal)
        })
        this.optionIndex = (this.optionIndex === 0 ? 1 : 0)
    }

    componentDidMount() {
        this.fetchData(() => {
            this.timerId && clearInterval(this.timerId)
            this.tick()
            this.timerId = setInterval(() => { this.tick() }, this.refreshIntervalSeconds * 1000)
        })
    }

    componentWillUnmount() {
        this.timerId && clearInterval(this.timerId)
    }

    render() {
        return (
            <BorderBox7>
                <div className="box-title">年级组考核【加扣分】</div>
                {this.state.option ? <Charts option={this.state.option} /> : ''}
            </BorderBox7>
        )
    }
}
