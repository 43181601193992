import React, {Component} from "react";
import {BorderBox12} from "@jiaminghi/data-view-react";
import "./index.scss"
import Meeting from "./components/meeting";
import BigStudy from "./components/bigStudy";
import Comprehensive from "./components/comprehensive";
import SocialActivities from "./components/socialActivities";
import StudentPortrait from "./components/studentPortrait"

export default () => {
	return (
		<BorderBox12 color={['transparent', 'transparent']} backgroundColor='transparent'>
			<div className="page">
				<div className="page-title">
					<i className="iconfont icon-gaikuang"></i>
					立德树人
				</div>

				<div className="page-body page-student-appraisal">
					<div className="page-body-left-half">
						<div className="page-body-left-half-top">
							<div className="inner-container">
								<Comprehensive/>
							</div>
						</div>
						<div className="page-body-left-half-bottom">
							<div className="inner-container">
								<SocialActivities/>
							</div>
						</div>
					</div>
					<div className="page-body-right-half">
						<div className="inner-container">
							<StudentPortrait />
						</div>
					</div>

				</div>
			</div>
		</BorderBox12>
	)
}
