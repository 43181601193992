import React, {Component} from "react";
import http from "../../../http"
import {BorderBox7} from "@jiaminghi/data-view-react";
import "./activities.scss"

export default class Activities extends Component {

	constructor(props) {
		super(props);

		this.state = {
			activities: [],
			top: 0
		}
	}

	componentDidMount() {
		http.get('/api/big_party_building/activities')
			.then((res) => {

				this.activitiesHeight = res.data.activities.length * 100;
				this.timerId && clearInterval(this.timerId)
				this.timerId = setInterval(() => {
					let top = this.state.top - 1
					if (Math.abs(top) > this.activitiesHeight)
						top = 0

					this.setState({
						activities: res.data.activities,
						top: top
					})
				}, 50)

			})
	}

	render() {
		let images = this.state.activities?.map((activity, index) => {
			return (
				<div className="activity" key={index}>
					<div className="image" style={{backgroundImage: "url(" + activity.activity_cover_image_url + ")"}}></div>
					<div className="title">{activity.activity_name}</div>
				</div>
			)
		})

		return (
			<BorderBox7>
				<div className="inner-container activities-container">
					<div className="activities" style={{top: this.state.top + 'px'}}>
						{images}
					</div>
				</div>
			</BorderBox7>
		)
	}
}
