import React, {Component} from "react";
import {BorderBox7} from "@jiaminghi/data-view-react";
import http from "../../../http";
import Charts from "@jiaminghi/data-view-react/es/charts";

export default class StudentSubject extends Component {
    constructor(props) {
        super(props);

        this.refreshIntervalSeconds = this.$config.refreshIntervalSeconds.studentGrowth || 9

        this.state = {
            countDown: 0,
            option: null
        }
    }

    componentDidMount() {
        http.get('/api/big_student_growth/subjects')
            .then((res) => {
                this.grades = res.data.grades
                this.gradeIndex = 0

                this.setState({ countDown: this.refreshIntervalSeconds })

                this.timerId && clearInterval(this.timerId)
                this.tick()
                this.timerId = setInterval(() => {
                    let nextCountDown = this.state.countDown - 1
                    if (nextCountDown <= 0) {
                        this.tick()
                        nextCountDown = this.refreshIntervalSeconds
                    }
                    this.setState({ countDown: nextCountDown })
                }, 1000)
            })
    }

    tick() {
        let grade = this.grades[this.gradeIndex]
        if (this.gradeIndex >= this.grades.length-1)
            this.gradeIndex = 0
        else
            this.gradeIndex += 1

        let categories = []
        let seriesData = []
        grade.subjects.forEach((item) => {
            categories.push(item.subject)
            seriesData.push(item.student_count)
        })

        let option = {
            title: {
                show: false
            },
            legend: {
                show: false
            },
            xAxis: {
                data: categories,
                axisLabel: {
                    style: {
                        fill: 'rgba(255,255,255,0.3)',
                        fontSize: 10,
                        rotate: 0
                    }
                }
            },
            yAxis: {
                data: 'value',
                min: Math.min(...seriesData),
                max: Math.max(...seriesData),
                splitLine: {
                    show: true,
                    style: {
                        stroke: 'rgba(255,255,255,0.1)',
                        lineWidth: 1
                    }
                }
            },
            grid: {
                left: '5%',
                top: '18%',
                right: '5%',
                bottom: '20%',
            },
            series: [
                {
                    data: seriesData,
                    type: 'bar',
                    label: {
                        show: true,
                        textStyle: {
                            fill: '#f18707'
                        }
                    },
                    gradient: {
                        color: ['rgba(251, 114, 147, .6)', 'rgba(251, 114, 147, .1)']
                    },
                    barStyle: {
                        stroke: '#fb7293'
                    }
                }
            ]
        }

        this.setState({
            title: grade.grade_name,
            option: option
        })
    }

    render() {
        return (
            <BorderBox7>
                <div className="box-title">学生选科情况 ({this.state.title}) ({this.state.countDown}秒后刷新...)</div>
                {this.state.option ?
                    <Charts option={this.state.option} style={{ width: '100%', height: '100%'}} />
                    : '' }
            </BorderBox7>
        )
    }
}
