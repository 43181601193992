import React, {Component} from "react";
import {BorderBox12, BorderBox7} from "@jiaminghi/data-view-react";
import "./index.scss"
import ExampleBarChart from "../components/exampleBarChart";
import http from "../../http";
import ExampleActiveRingChart from "../components/exampleActiveRingChart";
import StudentSubject from "./components/studentSubject";
import StudentLearningValid from "./components/studentLearningValid";
import Stars from "./components/stars";

export default class StudentGrowth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: null
        }
    }

    componentDidMount() {
        http.get('/api/big_teaching/count')
            .then((res) => {
                if (res.data.result !== 1)
                    return
                this.setState({
                    count: res.data.count
                })
            })
    }

    render() {
        return (
            <BorderBox12 color={['transparent', 'transparent']} backgroundColor='transparent'>
                <div className="page">
                    <div className="page-title">
                        <i className="iconfont icon-gaikuang"></i>
                        学生成长
                    </div>

                    <div className="page-body page-teacher-growth">
                        <div className="page-body-left-half">
                            <div className="page-body-left-half-top">
                                <div className="inner-container">
                                    <StudentSubject />
                                </div>
                            </div>
                            <div className="page-body-left-half-bottom">
                                <div className="inner-container">
                                    <Stars />
                                </div>
                            </div>
                        </div>
                        <div className="page-body-right-half">
                            <div className="inner-container">
                                <StudentLearningValid />
                            </div>
                        </div>

                    </div>
                </div>
            </BorderBox12>
        )
    }
}
