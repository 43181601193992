import React, {Component} from "react";
import {BorderBox7, Charts} from "@jiaminghi/data-view-react";
import http from "../../../http";

// 学习积分
export default class LearningPoints extends Component {
    constructor(props) {
        super(props);

        this.state = {
            option: null
        }
    }

    componentDidMount() {
        http.get('/api/big_party_building/learnPoints')
            .then((res) => {
                console.log(res)

                let categories = []
                let seriesData = []

                res.data.learnPoints.forEach((item) => {
                    categories.push(item.real_name)
                    seriesData.push(item.learn_points)
                })

                let option = {
                    title: {
                        show: false
                    },
                    legend: {
                        show: false
                    },
                    xAxis: {
                        data: categories,
                        axisLabel: {
                            style: {
                                fill: 'rgba(255,255,255,0.3)',
                                fontSize: 10,
                                rotate: 0
                            }
                        }
                    },
                    yAxis: {
                        data: 'value',
                        min: Math.min(...seriesData),
                        max: Math.max(...seriesData),
                        splitLine: {
                            show: true,
                            style: {
                                stroke: 'rgba(255,255,255,0.1)',
                                lineWidth: 1
                            }
                        }
                    },
                    grid: {
                        left: '5%',
                        top: '18%',
                        right: '5%',
                        bottom: '20%',
                    },
                    series: [
                        {
                            data: seriesData,
                            type: 'line',
                            smooth: true,
                            gradient: {
                                color: ['rgba(251, 114, 147, .6)', 'rgba(251, 114, 147, .1)']
                            },
                            barStyle: {
                                stroke: '#fb7293'
                            }
                        }
                    ]
                }

                this.setState({
                    option: option
                })

            })
    }

    render() {
        return (
            <BorderBox7>
                <div className="box-title">党员学习积分</div>
                {this.state.option ? <Charts option={this.state.option}/> : ''}
            </BorderBox7>
        )
    }
}
