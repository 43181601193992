import React, {Component} from "react";
import "./bigNumbers.scss"
import ExampleBigNumber from "../../components/exampleBigNumber";
import {BorderBox13, DigitalFlop} from "@jiaminghi/data-view-react";
import http from "../../../http";

export default class BigNumbers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            configStartYear: {
                number: [10000],
                content: '{nt}',
                style: {fontSize: 20}
            },
            configArea: {
                number: [10000],
                content: '{nt}',
                style: {fontSize: 20}
            },
            configStudentCount: {
                number: [10000],
                content: '{nt}',
                style: {fontSize: 20}
            },
            configAppLogin: {
                number: [10000],
                content: '{nt}',
                style: {fontSize: 20}
            }
        }
    }

    componentDidMount() {
        http.get('/api/big_overview/count')
            .then((res) => {

                this.setState({
                    configStartYear: {
                        number: [res.data.count.startYear],
                        content: '{nt}',
                        style: {fontSize: 20}
                    },
                    configArea: {
                        number: [res.data.count.area],
                        content: '{nt}',
                        style: {fontSize: 20}
                    },
                    configStudentCount: {
                        number: [res.data.count.studentCount],
                        content: '{nt}',
                        style: {fontSize: 20}
                    },
                    configAppLogin: {
                        number: [res.data.count.appLoginCount],
                        content: '{nt}',
                        style: {fontSize: 20}
                    }
                })

            })
    }

    render() {
        return (
            <div className="bigNumberContainer">
                <div className="item">
                    <div className="inner-container">
                        <BorderBox13>
                            <div className="bigContainer">
                                <DigitalFlop config={this.state.configStartYear} />
                                <div className="bigName">建校年份</div>
                            </div>
                        </BorderBox13>
                    </div>
                </div>
                <div className="item">
                    <div className="inner-container">
                        <BorderBox13>
                            <div className="bigContainer">
                                <DigitalFlop config={this.state.configArea} />
                                <div className="bigName">学校面积</div>
                            </div>
                        </BorderBox13>
                    </div>
                </div>
                <div className="item">
                    <div className="inner-container">
                        <BorderBox13>
                            <div className="bigContainer">
                                <DigitalFlop config={this.state.configStudentCount} />
                                <div className="bigName">学生人数</div>
                            </div>
                        </BorderBox13>
                    </div>
                </div>
                <div className="item">
                    <div className="inner-container">
                        <BorderBox13>
                            <div className="bigContainer">
                                <DigitalFlop config={this.state.configAppLogin} />
                                <div className="bigName">APP登录</div>
                            </div>
                        </BorderBox13>
                    </div>
                </div>
            </div>
        )
    }
}
