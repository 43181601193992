import {BorderBox7, Charts} from "@jiaminghi/data-view-react";
import React, {Component} from "react";
import http from "../../../http";

export default class StudentPortrait extends Component {
	constructor(props) {
		super(props);

		this.refreshIntervalSeconds = 5;

		this.state = {
			countDown: 0,
			studentIndex: -1
		}
	}

	componentDidMount() {
		http.get('/api/big_student_quality/studentPortrait')
			.then((res) => {
				this.students = res.data.students

				this.setState({
					countDown: this.refreshIntervalSeconds,
					studentIndex: 0
				})

				this.timerId && clearInterval(this.timerId)
				this.timerId = setInterval(() => {

					let nextCountDown = this.state.countDown - 1
					if (nextCountDown <= 0) {
						if (this.state.studentIndex >= this.students.length - 1)
							this.setState({ studentIndex: 0 })
						else
							this.setState({ studentIndex: this.state.studentIndex + 1 })

						nextCountDown = this.refreshIntervalSeconds
					}

					this.setState({ countDown: nextCountDown })

				}, 1000);
			})
	}

	render() {
		if (this.state.studentIndex < 0 || this.students?.length === 0)
			return <></>

		let student = this.students[this.state.studentIndex]
		let seriesData = [
			student.portrait.academicScore,
			student.portrait.artScore,
			student.portrait.healthScore,
			student.portrait.moralScore,
			student.portrait.socialScore
		]
		let maxValue = Math.max(...seriesData)
		maxValue += 10

		const option1 = {
			radar: {
				radius: '65%',
				indicator: [
					{ name: '品德表现', max: maxValue },
					{ name: '学业水平', max: maxValue },
					{ name: '艺术修养', max: maxValue },
					{ name: '身心健康', max: maxValue },
					{ name: '社会实践', max: maxValue },
				],
				polygon: true,
				axisLine: {
					style: {
						stroke: 'rgba(251, 114, 147, .3)'
					}
				},
				axisLabel: {
					show: true,
					style: {
						fill: 'rgb(238,208,6)',
						fontSize: 12
					}
				},
				splitLine: {
					style: {
						stroke: 'rgba(251, 114, 147, .5)',
						fill: 'rgba(251, 114, 147, .2)'
					}
				}
			},
			series: [
				{
					type: 'radar',
					data: seriesData
				}
			]
		}

		return (
			<BorderBox7>
				<div className="box-title">{student.realName}({student.classNo})综合素质画像 ({this.state.countDown}秒后切换...)</div>
				<Charts option={option1}/>
			</BorderBox7>
		)
	}
}
