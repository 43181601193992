import React, {Component} from "react";
import {BorderBox7, Charts} from "@jiaminghi/data-view-react";
import http from "../../../http";

// 思想政治微课堂
export default class PoliticalMicroClassrooms extends Component {
    constructor(props) {
        super(props);

        this.refreshIntervalSeconds = this.$config.refreshIntervalSeconds.schoolBase || 9

        this.state = {
            title: '',
            countDown: 0,
            option: null
        }
    }

    componentDidMount() {
        http.get('/api/big_school_base/politicalMicroClassrooms')
            .then((res) => {

                this.setState({
                    title: '',
                    countDown: this.refreshIntervalSeconds
                })

                this.grades = res.data.grades
                this.gradeIndex = 0

                this.tick()

                this.timerId && clearInterval(this.timerId)
                this.timerId = setInterval(() => {
                    let nextCountDown = this.state.countDown - 1
                    if (nextCountDown <= 0) {
                        if (this.gradeIndex >= this.grades.length-1)
                            this.gradeIndex = 0
                        else
                            this.gradeIndex += 1
                        this.tick()
                        nextCountDown = this.refreshIntervalSeconds
                    }

                    this.setState({ countDown: nextCountDown })
                }, 1000);

            })
    }

    tick() {
        let grade = this.grades[this.gradeIndex]

        let categories = []
        let seriesData = []

        grade.classes.forEach((item) => {
            categories.push(item.classNo)
            seriesData.push(item.score)
        })

        let option = {
            title: {
                show: false
            },
            legend: {
                show: false
            },
            xAxis: {
                data: categories,
                axisLabel: {
                    style: {
                        fill: 'rgba(255,255,255,0.3)',
                        fontSize: 10,
                        rotate: 45
                    }
                }
            },
            yAxis: {
                data: 'value',
                min: Math.min(...seriesData),
                max: Math.max(...seriesData),
                splitLine: {
                    show: true,
                    style: {
                        stroke: 'rgba(255,255,255,0.1)',
                        lineWidth: 1
                    }
                }
            },
            grid: {
                left: '5%',
                top: '18%',
                right: '5%',
                bottom: '20%',
            },
            series: [
                {
                    data: seriesData,
                    type: 'bar',
                    smooth: true,
                    label: {
                        show: true,
                        style: {
                            fill: '#e79205'
                        }
                    },
                    gradient: {
                        color: ['rgba(251, 114, 147, .6)', 'rgba(251, 114, 147, .1)']
                    },
                    barStyle: {
                        stroke: '#fb7293'
                    }
                }
            ]
        }

        this.setState({
            title: grade.name,
            option: option
        })
    }

    render() {
        return (
            <BorderBox7>
                <div className="box-title">思政微课堂情况({this.state.title}) ({this.state.countDown}秒后切换...)</div>
                {this.state.option ? <Charts option={this.state.option}/> : ''}
            </BorderBox7>
        )
    }
}
