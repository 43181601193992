import React, {Component} from "react";
import {BorderBox7, Charts} from "@jiaminghi/data-view-react";
import http from "../../../http";

export default class Comprehensive extends Component {
    constructor(props) {
        super(props);

        this.refreshIntervalSeconds = this.$config.refreshIntervalSeconds.studentQuality || 9

        this.labelOption = {
            show: true,
            style: {
                fill: '#e79205'
            }
        }

        this.state = {
            countDown: 0,
            option: null
        }
    }

    componentDidMount() {
        http.get('/api/big_student_quality/comprehensive')
            .then((res) => {

                this.grades = res.data.grades
                this.jobs = []
                this.jobIndex = 0

                // 一次只显示5个班级
                this.grades.forEach((grade) => {
                    let pages = Math.ceil(grade.classes.length / 5)
                    for (let page=0; page<pages; page++) {
                        this.jobs.push({
                            grade: grade,
                            start: page * 5,
                            pageSize: 5
                        })
                    }
                })

                this.setState({ countDown: this.refreshIntervalSeconds })

                this.timerId && clearInterval(this.timerId)
                this.tick()
                this.timerId = setInterval(() => {

                    let nextCountDown = this.state.countDown - 1
                    if (nextCountDown <= 0) {
                        if (this.jobIndex >= this.jobs.length-1)
                            this.jobIndex = 0
                        else
                            this.jobIndex += 1
                        this.tick()
                        nextCountDown = this.refreshIntervalSeconds
                    }

                    this.setState({ countDown: nextCountDown })

                }, 1000)

            })
    }

    tick() {
        let job = this.jobs[this.jobIndex]
        let grade = job.grade

        console.log(job)

        let categories = []
        let moralData = []
        let academicData = []
        let artData = []
        let healthData = []
        let socialData = []

        for (let i=job.start; i<Math.min(grade.classes.length, job.start + job.pageSize); i++) {
            let item = job.grade.classes[i]
            categories.push(item.no)
            moralData.push(item.moral)
            academicData.push(item.academic)
            artData.push(item.art)
            healthData.push(item.health)
            socialData.push(item.social)
        }

        let option = {
            title: {
                show: false
            },
            legend: {
                show: true,
                top: '22%',
                textStyle: {
                    fill: '#999999',
                    fontSize: '0.2rem'
                },
                data: ['德育考核', '学业水平', '艺术修养', '身心健康', '社会实践']
            },
            xAxis: {
                data: categories,
                axisLabel: {
                    style: {
                        fill: 'rgba(255,255,255,0.3)',
                        fontSize: 10,
                        rotate: 45
                    }
                }
            },
            yAxis: {
                data: 'value',
                splitLine: {
                    show: true,
                    style: {
                        stroke: 'rgba(255,255,255,0.1)',
                        lineWidth: 1
                    }
                }
            },
            grid: {
                left: '5%',
                top: '28%',
                right: '5%',
                bottom: '20%',
                style: {
                    fill: 'rgba(255, 255, 255, 0.2)',
                }
            },
            series: [
                {
                    name: '德育考核',
                    type: 'bar',
                    data: moralData,
                    label: this.labelOption
                },
                {
                    name: '学业水平',
                    type: 'bar',
                    data: academicData,
                    label: this.labelOption
                },
                {
                    name: '艺术修养',
                    type: 'bar',
                    data: artData,
                    label: this.labelOption
                },
                {
                    name: '身心健康',
                    type: 'bar',
                    data: healthData,
                    label: this.labelOption
                },
                {
                    name: '社会实践',
                    type: 'bar',
                    data: socialData,
                    label: this.labelOption
                }
            ]
        }

        console.log(option)

        this.setState({
            title: grade.grade_name,
            option: option
        })
    }

    render() {
        return (
            <BorderBox7>
                <div className="box-title">学生综合素质(A等人数)({this.state.title}) ({this.state.countDown}秒后切换...)</div>
                {this.state.option ?  <Charts option={this.state.option}/> : ''}
            </BorderBox7>
        )
    }
}
