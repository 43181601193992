import React, {Component} from "react";
import http from "../../../http"
import {BorderBox7} from "@jiaminghi/data-view-react";
import "./news.scss"

export default class News extends Component {
    constructor(props) {
        super(props);

        //this.refreshIntervalSeconds = this.$config.globalRefreshIntervalSeconds || 9;
        this.refreshIntervalSeconds = 30;

        console.log(this.$config)

        this.state = {
            countDown: this.refreshIntervalSeconds,
            top: 0,
            bodyHeight: 500,
            awards: {}
        }
    }

    componentDidMount() {
        http.get('/api/big_overview/news')
            .then((res) => {
                this.news = res.data.news
                this.newIndex = 0

                this.tick()

                this.timerId && clearInterval(this.timerId)
                this.timerId = setInterval(() => {
                    if (this.state.countDown <= 1) {
                        if (this.newIndex >= this.news.length)
                            this.newIndex = 0
                        else
                            this.newIndex += 1
                        this.tick()
                        this.setState({countDown: this.refreshIntervalSeconds })
                        return
                    }

                    this.s=this.setState({ countDown: this.state.countDown - 1 })
                }, 1000)
            })
    }

    tick() {
        let news = this.news[this.newIndex]
        this.setState({
            news: news,
            top: 0
        })

        this.scrollTimerId && clearInterval(this.scrollTimerId)
        this.scrollTimerId = setInterval(() => {
            this.setState({
                top: this.state.top-1
            })
        }, 100)

    }

    render() {
        return (
            <BorderBox7>
                <div className="innerContainer">
                    <div className="news-activity">

                        <div className="news-activity-thumb" style={{ backgroundImage: 'url(' + this.state.news?.coverImage + ')' }} />
                        <div className="news-activity-body" >
                            <div className="news-activity-body-container" style={{top: this.state.top + 'px'}} dangerouslySetInnerHTML={{__html: this.state.news?.content}}>

                            </div>
                        </div>

                    </div>
                </div>
            </BorderBox7>
        )
    }
}
