import React, {Component} from "react";
import { Charts } from "@jiaminghi/data-view-react";

export default class ExampleBarChart extends Component {
    render() {
        const option1 = {
            title: {
                text: '教师',
                offset: [0,-20],
                style: {
                    fontSize: 12
                }
            },
            legend: {
                show: false
            },
            xAxis: {
                data: ['1901', '1902', '1903', '1904', '1905', '1906', '1907', '1908', '1909', '1910', '1911', '1912']
            },
            yAxis: {
                data: 'value',
                axisLine: {
                    show: false
                },
                axisLabel: {
                    show: false
                }
            },
            grid: {
                left: '5%',
                top: '25%',
                right: '5%',
                bottom: '20%'
            },
            series: [
                {
                    data: [1200, 2230, 1900, 2100, 3500, 4200, 3985, 1200, 2230, 1900, 2100, 3500, 4200, 3985],
                    type: 'bar',
                    gradient: {
                        color: ['rgba(251, 114, 147, .6)', 'rgba(251, 114, 147, .1)']
                    },
                    barStyle: {
                        stroke: '#fb7293'
                    }
                }
            ]
        }

        return (
            <Charts option={option1} />
        )
    }
}
