import React, {Component} from "react";
import {BorderBox12, BorderBox7} from "@jiaminghi/data-view-react";
import "./index.scss"
import BigNumbers from "./components/bigNumbers";
import ExampleBarChart from "../components/exampleBarChart";
import ExampleScrollBoard from "../components/exampleScrollBoard";
import News from "./components/news";
import Awards from "./components/awards";
import TeacherStudentComposition from "./components/teacherStudentComposition";

export default class Overview extends Component {
    render() {
        return (
            <BorderBox12 color={['transparent', 'transparent']} backgroundColor='transparent'>
                <div className="page">

                    <div className="page-title">
                        <i className="iconfont icon-gaikuang"></i>
                        学校概况
                    </div>

                    <div className="page-body page-overview">
                        <div className="page-body-left">
                            <div className="page-body-left-top-left">
                                <div className="inner-container">
                                    <BigNumbers/>
                                </div>
                            </div>
                            <div className="page-body-left-top-right">
                                <div className="inner-container">
                                    <TeacherStudentComposition />
                                </div>
                            </div>
                            <div className="page-body-left-bottom">
                                <div className="inner-container">
                                    <Awards />
                                </div>
                            </div>
                        </div>
                        <div className="page-body-right">
                            <div className="inner-container">
                                <News/>
                            </div>
                        </div>
                    </div>

                </div>
            </BorderBox12>
        )
    }
}
