import React, {Component} from "react";
import {BorderBox7, Charts} from "@jiaminghi/data-view-react";
import http from "../../../http";

export default class Meeting extends Component {
    constructor(props) {
        super(props);

        this.refreshIntervalSeconds = this.$config.refreshIntervalSeconds.studentQuality || 9

        this.state = {
            countDown: 0,
            option: null
        }
    }

    componentDidMount() {
        http.get('/api/big_student_quality/meeting')
            .then((res) => {

                this.grades = res.data.grades
                this.gradeIndex = 0

                this.setState({ countDown: this.refreshIntervalSeconds })

                this.timerId && clearInterval(this.timerId)
                this.tick()
                this.timerId = setInterval(() => {

                    let nextCountDown = this.state.countDown - 1
                    if (nextCountDown <= 0) {
                        this.gradeIndex += 1
                        if (this.gradeIndex >= this.grades.length)
                            this.gradeIndex = 0
                        this.tick()
                        nextCountDown = this.refreshIntervalSeconds
                    }

                    this.setState({ countDown: nextCountDown })

                }, 1000)

            })
    }

    tick() {
        let grade = this.grades[this.gradeIndex]

        let categories = []
        let data = []
        grade.classes.forEach((item) => {
            categories.push(item.no)
            data.push(item.count)
        })

        let option = {
            title: {
                show: false
            },
            legend: {
                show: false
            },
            xAxis: {
                data: categories,
                axisLabel: {
                    style: {
                        fill: 'rgba(255,255,255,0.3)',
                        fontSize: 10,
                        rotate: 45
                    }
                }
            },
            yAxis: {
                data: 'value',
                min: Math.min(...data),
                max: Math.max(...data),
                splitLine: {
                    show: true,
                    style: {
                        stroke: 'rgba(255,255,255,0.1)',
                        lineWidth: 1
                    }
                }
            },
            grid: {
                left: '5%',
                top: '18%',
                right: '5%',
                bottom: '20%',
            },
            series: [
                {
                    data: data,
                    type: 'bar',
                    gradient: {
                        color: ['rgba(251, 114, 147, .6)', 'rgba(251, 114, 147, .1)']
                    },
                    barStyle: {
                        stroke: '#fb7293'
                    }
                }
            ]
        }

        console.log(option)

        this.setState({
            title: grade.grade_name,
            option: option
        })
    }

    render() {
        return (
            <BorderBox7>
                <div className="box-title">班会、两操、集会考核({this.state.title})</div>
                {this.state.option ?  <Charts option={this.state.option}/> : ''}
            </BorderBox7>
        )
    }
}
