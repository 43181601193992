import React, {Component} from "react";
import {BorderBox7, Charts} from "@jiaminghi/data-view-react";
import "./microVideo.scss"
// https://zzsz.syschools.cn/uploads/202206/szwkt/szwkt-1.mp4


export default class MicroVideo extends Component {
	render() {
		return (
			<BorderBox7>
				<div className="box-title">思政微课堂</div>
				<div className="inner-container" style={{textAlign: "center"}}>
					<video width="100%" height="100%" controls autoPlay="autoPlay" loop="loop" muted="muted" >
						<source src="https://zzsz.syschools.cn/static/videos/1.mp4"
								type="video/mp4"></source>
					</video>
				</div>
			</BorderBox7>
		)
	}
}
