import React, {Component} from "react";
import {BorderBox7} from "@jiaminghi/data-view-react";
import http from "../../../http";
import Charts from "@jiaminghi/data-view-react/es/charts";

// 教师、学生的组成
export default class TeacherStudentComposition extends Component {
    constructor(props) {
        super(props);

        this.state = {
            countDown: 9,
            option: null
        }
    }

    componentDidMount() {
        http.get('/api/big_overview/teacherStudentComposition')
            .then((res) => {
                console.log(res.data)

                this.teacherComposition = res.data.teacherComposition
                this.studentComposition = res.data.studentComposition
                this.jobCode = 'teacher'

                this.timerId && clearInterval(this.timerId)
                this.tick()
                this.timerId = setInterval(() => {
                    if (this.state.countDown <= 1) {
                        this.jobCode = (this.jobCode === 'teacher' ? 'student' : 'teacher')
                        this.tick()
                        this.setState({ countDown: 9 })
                        return
                    }

                    this.setState({ countDown: this.state.countDown - 1 })
                }, 1000)
            })
    }

    tick() {
        let categories = []
        let series0Data = []
        let title = ''

        if (this.jobCode === 'teacher') {
            title = '教师情况'
            categories = Object.keys(this.teacherComposition)
            series0Data = Object.values(this.teacherComposition)
        }

        if (this.jobCode === 'student') {
            title = '学生情况'
            categories = Object.keys(this.studentComposition)
            series0Data = Object.values(this.studentComposition)
        }

        let option = {
            title: {
                show: false
            },
            legend: {
                show: false
            },
            xAxis: {
                data: categories,
                axisLabel: {
                    style: {
                        fill: 'rgba(255,255,255,0.3)',
                        fontSize: 10,
                        rotate: 45
                    }
                }
            },
            yAxis: {
                data: 'value',
                min: Math.min(...series0Data),
                max: Math.max(...series0Data),
                splitLine: {
                    show: true,
                    style: {
                        stroke: 'rgba(255,255,255,0.1)',
                        lineWidth: 1
                    }
                }
            },
            grid: {
                left: '5%',
                top: '18%',
                right: '5%',
                bottom: '20%',
            },
            series: [
                {
                    data: series0Data,
                    type: 'bar',
                    label: {
                        show: true,
                        textStyle: {
                            fill: '#f18707'
                        }
                    },
                    gradient: {
                        color: ['rgba(251, 114, 147, .6)', 'rgba(251, 114, 147, .1)']
                    },
                    barStyle: {
                        stroke: '#fb7293'
                    }
                }
            ]
        }

        this.setState({
            title: title,
            option: option
        })
    }

    render() {
        return (
            <BorderBox7>
                <div className="box-title">{this.state.title}</div>
                {this.state.option ?
                    <Charts option={this.state.option} style={{width:'100%', height:'100%'}}/>
                    : ''}
            </BorderBox7>
        )
    }
}
