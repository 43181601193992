import React, {Component} from "react";
import {BorderBox7} from "@jiaminghi/data-view-react";
import "./awards.scss"
import http from "../../../http";

export default class Awards extends Component {
    constructor(props) {
        super(props);

        this.state = {
            awards: [],
            left: 0
        }
    }

    componentDidMount() {
        http.get('/api/big_overview/awards')
            .then((res) => {
                this.setState({
                    awards: res.data.awards,
                    left: 0
                })

                this.awards_width = res.data.awards.length * 100;
                this.timerId && clearInterval(this.timerId)
                this.timerId = setInterval(() => {
                    let left = this.state.left - 1
                    if (Math.abs(left) > this.awards_width)
                        left = 0

                    this.setState({
                        left: left
                    })
                }, 50)
            })
    }

    render() {
        let imageIndex = 0
        let images = this.state.awards.map((award) => {
            imageIndex += 1
            return (<img src={award.award_url} style={{height: '100%'}} key={"image" + imageIndex} />)
        })

        return (
            <BorderBox7>
                <div className="inner-container awards">
                    <div className="photos" style={{left: this.state.left + 'px'}}>
                        {images}
                    </div>
                </div>
            </BorderBox7>
        )
    }
}
