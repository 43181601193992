import React, {Component} from "react";
import {BorderBox7, Charts} from "@jiaminghi/data-view-react";
import http from "../../../http";

export default class Bedroom extends Component {
    constructor(props) {
        super(props);

        this.refreshIntervalSeconds = this.$config.refreshIntervalSeconds.manage || 9;

        this.state = {
            countDown: 0,
            option: null
        }
    }

    componentDidMount() {
        http.get('/api/big_manage/bedroom')
            .then((res) => {
                if (res.data.result != 1)
                    return

                this.grades = res.data.data
                this.gradeIndex = 0;

                this.setState({ countDown: this.refreshIntervalSeconds })

                this.timerId && clearInterval(this.timerId)
                this.tick()
                this.timerId = setInterval(() => {
                    let nextCountDown = this.state.countDown - 1
                    if (nextCountDown <= 0) {
                        this.tick()
                        nextCountDown = this.refreshIntervalSeconds
                    }

                    this.setState({ countDown: nextCountDown })

                }, 1000)
            })
    }

    componentWillUnmount() {
        this.timerId && clearInterval(this.timerId)
    }

    tick() {
        let grade = this.grades[this.gradeIndex]
        if (this.gradeIndex >= this.grades.length-1)
            this.gradeIndex = 0
        else
            this.gradeIndex ++

        let categories = []
        let series_data = []
        grade.classes.forEach((item, index) => {
            categories.push(item.no)
            series_data.push(item.score)
        })

        const option = {
            title: {
                show: false
            },
            xAxis: {
                data: categories,
                axisLabel: {
                    style: {
                        fill: 'rgba(255,255,255,0.3)',
                        fontSize: 10,
                        rotate: 45
                    }
                }
            },
            yAxis: {
                data: 'value',
                splitLine: {
                    show: true,
                    style: {
                        stroke: 'rgba(255,255,255,0.1)',
                        lineWidth: 1
                    }
                }
            },
            grid: {
                left: '5%',
                top: '18%',
                right: '5%',
                bottom: '20%',
                style: {
                    fill: 'rgba(255, 255, 255, 0.2)',
                }
            },
            series: [
                {
                    data: series_data,
                    type: 'bar',
                    label: {
                        show: true,
                        style: {
                            fill: '#e79205'
                        }
                    },
                    gradient: {
                        color: ['#37a2da', '#67e0e3']
                    }
                }
            ]
        }

        this.setState({
            title: grade.name,
            option: option
        })
    }

    render() {
        return (
            <BorderBox7>
                <div className="box-title">寝室考核({this.state.title}加扣分情况) ({this.state.countDown}秒后刷新...)</div>
                <Charts option={this.state.option}/>
            </BorderBox7>
        )
    }
}
