import React, {Component} from "react";
import {BorderBox7, Charts} from "@jiaminghi/data-view-react";
import http from "../../../http";

// 兴趣班
export default class InterestClasses extends Component {
	constructor(props) {
		super(props);

		this.labelOption = {
			show: true,
			style: {
				fill: '#e79205'
			}
		}

		this.options = []
		this.state = {
			optionIndex: 0,
            countDown: 9
		}
	}

	componentDidMount() {
		http.get('/api/big_school_base/interestClasses')
			.then((res) => {

				let options = []
				let pages = Math.ceil(res.data.classes.length / 5)

				for (let page = 0; page < pages; page++) {

					let categories = []
					let series0Data = []   // 人数

					for (let i = page * 5; i < Math.min(res.data.classes.length, page * 5 + 5); i++) {
						categories.push(res.data.classes[i].name)
						series0Data.push(res.data.classes[i].studentCount)
					}

					options.push({
						title: {
							show: false
						},
						legend: {
							show: false
						},
						xAxis: {
							data: categories,
							axisLabel: {
								style: {
									fill: 'rgba(255,255,255,0.3)',
									fontSize: 10,
									rotate: 0
								}
							}
						},
						yAxis: {
							data: 'value',
							splitLine: {
								show: true,
								style: {
									stroke: 'rgba(255,255,255,0.1)',
									lineWidth: 1
								}
							}
						},
						grid: {
							left: '5%',
							top: '15%',
							right: '5%',
							bottom: '20%',
							style: {
								fill: 'rgba(255, 255, 255, 0.2)',
							}
						},
						series: [
							{
								name: '人数',
								type: 'bar',
								data: series0Data,
								label: this.labelOption,
								color: '#0458f8'
							}
						]
					})
				}

				this.options = options;
				this.timerId && clearInterval(this.timerId)
                this.timerId = setInterval(() => {

                    let nextCountDown = this.state.countDown - 1
                    if (nextCountDown <= 0) {
                        nextCountDown = 9;
                        let nextOptionIndex = this.state.optionIndex + 1
                        if (nextOptionIndex >= this.options.length)
                            nextOptionIndex = 0

                        this.setState({ optionIndex: nextOptionIndex })
                    }
                    this.setState({ countDown: nextCountDown })

                }, 1000)

			})
	}

	render() {
		return (
			<BorderBox7>
				<div className="box-title">校本课程选课情况 ({this.state.countDown}秒后切换)</div>
				{this.state.optionIndex < this.options.length ? <Charts option={this.options[this.state.optionIndex]}/> : ''}
			</BorderBox7>
		)
	}
}
