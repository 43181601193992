import React, {Component} from "react";
import { ActiveRingChart } from "@jiaminghi/data-view-react";

export default class ExampleActiveRingChart extends Component {
    render() {
        const config = {
            radius: '80%',
            activeRadius: '90%',
            data: [
                {
                    name: '周口',
                    value: 55
                },
                {
                    name: '南阳',
                    value: 120
                },
                {
                    name: '西峡',
                    value: 78
                },
                {
                    name: '驻马店',
                    value: 66
                },
                {
                    name: '新乡',
                    value: 80
                }
            ],
                lineWidth: 5,
            digitalFlopStyle: {
                fontSize: 10
            }
        }

        return (
            <ActiveRingChart config={config} style={{ width: '100%', height: '100%'}} />
        )
    }
}
