import React, {Component} from "react";
import {BorderBox7, Charts} from "@jiaminghi/data-view-react";
import http from "../../../http";

export default class AppraisalClass extends Component {
    constructor(props) {
        super(props);

        this.refreshIntervalSeconds = this.$config.refreshIntervalSeconds.appraisal || 9;

        this.state = {
            countDown: 0,
            option: null
        }
    }

    makeOption(categories, series_data) {
        return {
            title: {
                show: false
            },
            legend: {
                show: false
            },
            xAxis: {
                data: categories,
                axisLabel: {
                    style: {
                        fill: 'rgba(255,255,255,0.3)',
                        fontSize: 10,
                        rotate: 45
                    }
                }
            },
            yAxis: {
                data: 'value',
                splitLine: {
                    show: true,
                    style: {
                        stroke: 'rgba(255,255,255,0.1)',
                        lineWidth: 1
                    }
                }
            },
            grid: {
                left: '5%',
                top: '18%',
                right: '5%',
                bottom: '15%',
                style: {
                    fill: 'rgba(255, 255, 255, 0.2)',
                }
            },
            series: [
                {
                    data: series_data,
                    type: 'line',
                    lineArea: {
                        show: true,
                        gradient: ['rgba(55, 162, 218, 0.6)', 'rgba(55, 162, 218, 0.2)']
                    }
                }
            ]
        }
    }

    tick() {
        let categories = []
        let series_data = []
        this.grades[this.gradeIndex].classes.forEach((item) => {
            categories.push(item.no)
            series_data.push(item.score)
        })

        this.setState({
            title: this.grades[this.gradeIndex].name,
            option: this.makeOption(categories, series_data)
        })
    }

    componentDidMount() {
        http.get('/api/big_appraisal/class')
            .then((res) => {
                this.grades = res.data.grades
                this.gradeIndex = 0

                this.setState({
                    countDown: this.refreshIntervalSeconds
                })

                this.timerId && clearInterval(this.timerId)
                this.tick()
                this.timerId = setInterval(() => {

                    let nextCountDown = this.state.countDown - 1
                    if (nextCountDown <= 0) {
                        if (this.gradeIndex >= this.grades.length - 1)
                            this.gradeIndex = 0
                        else
                            this.gradeIndex += 1

                        this.tick()
                        nextCountDown = this.refreshIntervalSeconds
                    }

                    this.setState({ countDown: nextCountDown })

                }, 1000);
            })
    }

    componentWillUnmount() {
        this.timerId && clearInterval(this.timerId)
    }

    render() {
        return (
            <BorderBox7>
                <div className="box-title">班级考核加扣分({this.state.title}) ({this.state.countDown}秒后刷新...)</div>
                <Charts option={this.state.option}/>
            </BorderBox7>
        )
    }
}
