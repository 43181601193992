import React, {Component} from "react";
import {ActiveRingChart, BorderBox7} from "@jiaminghi/data-view-react";
import http from "../../../http";
import Charts from "@jiaminghi/data-view-react/es/charts";

export default class StudentLearningValid extends Component {
	constructor(props) {
		super(props);

		this.refreshIntervalSeconds = this.$config.refreshIntervalSeconds.studentGrowth || 9

		this.state = {
			option: null
		}
	}

	componentDidMount() {
		http.get('/api/big_student_growth/learningValid')
			.then((res) => {
				this.grades = res.data.grades
				this.gradeIndex = 0

				this.timerId && clearInterval(this.timerId)
				this.tick()
				this.timerId = setInterval(() => {
					this.tick()
				}, this.refreshIntervalSeconds * 1000)
			})
	}

	tick() {
		let grade = this.grades[this.gradeIndex]
		if (this.gradeIndex >= this.grades.length - 1)
			this.gradeIndex = 0
		else
			this.gradeIndex += 1

		let data = []
		grade.feedbacks.forEach((item) => {
			if (item.tp_count === 0)
				return
			data.push({
				name: item.feedback_name,
				value: parseInt(item.tp_count)
			})
		})

		let option = {
			radius: '60%',
			activeRadius: '75%',
			lineWidth: 50,
			data: data,
			color: [ '#3c5afd', '#f87b24', '#61ec47', '#37f1c6', '#ff0000' ],
			digitalFlopStyle: {
				fontSize: 20
			}
		}

		this.setState({
			title: grade.name,
			option: option
		})
	}

	render() {
		return (
			<BorderBox7>
				<div className="box-title">学生课堂学习有效性 ({this.state.title})</div>
				{this.state.option ?
					<ActiveRingChart config={this.state.option} style={{ width: '100%', height: '100%'}} />
					: ''}
			</BorderBox7>
		)
	}
}
