import React, {Component} from "react";
import {BorderBox7, Charts} from "@jiaminghi/data-view-react";
import http from "../../../http";

// 食堂
export default class Canteen extends Component {
    constructor(props) {
        super(props);

        this.refreshIntervalSeconds = this.$config.refreshIntervalSeconds.manage || 9;

        this.state = {
            option: null
        }
    }

    tick() {
        http.get('/api/big_manage/canteen')
            .then((res) => {

                let categories = []
                let series0_data = []
                let series1_data = []

                let min = 1000
                let max = 0
                res.data.items.forEach((item) => {
                    categories.push(item.canteen_month)
                    series0_data.push(item.canteen_satisfaction)
                    series1_data.push(item.canteen_raw)

                    max = Math.max(min, item.canteen_raw, item.canteen_satisfaction)
                    min = Math.min(max, item.canteen_raw, item.canteen_satisfaction)
                })

                min -= 5
                max += 5

                let option = {
                    title: {
                        show: false
                    },
                    legend: {
                        data: [
                            {
                                name: '学生食堂满意度',
                                color: '#f1829f'
                            },
                            {
                                name: '原材料控制比',
                                color: '#aeeff0'
                            }
                        ],
                        bottom: '5%',
                        right: '5%',
                        textStyle: {
                            fill: '#999999'
                        },
                    },
                    xAxis: {
                        data: categories,
                        axisLabel: {
                            style: {
                                fill: 'rgba(255,255,255,0.3)',
                                fontSize: 10,
                                rotate: 0
                            }
                        }
                    },
                    yAxis: [
                        {
                            name: '学生食堂满意度',
                            data: 'value',
                            min: 0,
                            max: 100,
                            splitLine: {
                                show: false
                            }
                        },
                        {
                            name: '原材料控制比',
                            data: 'value',
                            position: 'right',
                            min: 0,
                            max: 100,
                            splitLine: {
                                show: false
                            }
                        }
                    ],
                    grid: {
                        left: '5%',
                        top: '18%',
                        right: '5%',
                        bottom: '25%',
                    },
                    series: [
                        {
                            name: '学生食堂满意度',
                            data: series0_data,
                            type: 'bar',
                            gradient: {
                                color: ['rgba(251,114,147,.6)', 'rgba(251,114,147,.1)']
                            },
                            barStyle: {
                                stroke: 'rgba(251, 114, 147, 1)'
                            }
                        },
                        {
                            name: '原材料控制比',
                            data: series1_data,
                            type: 'bar',
                            gradient: {
                                color: ['rgba(103, 224, 227, .6)', 'rgba(103, 224, 227, .1)']
                            },
                            barStyle: {
                                stroke: 'rgba(103, 224, 227, 1)'
                            }
                        }
                    ]
                }

                this.setState({
                    option: option
                })
            })
    }

    componentDidMount() {

        this.timerId && clearInterval(this.timerId)
        this.tick()
        this.timerId = setInterval(() => {

            this.tick()

        }, this.refreshIntervalSeconds * 1000);
    }

    componentWillUnmount() {
        this.timerId && clearInterval(this.timerId)
    }

    render() {
        return (
            <BorderBox7>
                <div className="box-title">学生食堂满意度与原材料控制比</div>
                {this.state.option ?
                    <Charts option={this.state.option}/> : '' }
            </BorderBox7>
        )
    }
}
