import React, {Component} from "react";
import {BorderBox12} from "@jiaminghi/data-view-react";
import "./index.scss"
import Activities from "./components/activities";
import PartyBranches from "./components/partyBranches";
import LearningPoints from "./components/learningPoints";

export default class PartyBuilding extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <BorderBox12 color={['transparent', 'transparent']} backgroundColor='transparent'>
                <div className="page">

                    <div className="page-title">
                        <i className="iconfont icon-gaikuang"></i>
                        智慧党建
                    </div>

                    <div className="page-body page-overview">
                        <div className="page-body-left">
                            <div className="page-body-left-top">
                                <div className="inner-container">
                                    <PartyBranches />
                                </div>
                            </div>
                            <div className="page-body-left-bottom">
                                <div className="inner-container">
                                    <LearningPoints  />
                                </div>
                            </div>
                        </div>
                        <div className="page-body-right">
                            <div className="inner-container">
                                <Activities/>
                            </div>
                        </div>
                    </div>

                </div>
            </BorderBox12>
        )
    }
}
