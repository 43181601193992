import React, {Component} from "react";
import {BorderBox7, Charts} from "@jiaminghi/data-view-react";
import http from "../../../http";

// 党支部人数
export default class PartyBranches extends Component {
    constructor(props) {
        super(props);

        this.labelOption = {
            show: true,
            style: {
                fill: '#e79205'
            }
        }

        this.state = {
            option: null
        }
    }

    componentDidMount() {
        http.get('/api/big_party_building/partyBranches')
            .then((res) => {

                let categories = []
                let series0Data = []   // 党员人数
                let series1Data = []   // 预备党员
                let series2Data = []   // 入党积极分子

                res.data.partyBranches.forEach((item) => {
                    categories.push(item.name)
                    series0Data.push(item.officialCount)
                    series1Data.push(item.probationaryCount)
                    series2Data.push(item.activists)
                })

                let option = {
                    title: {
                        show: false
                    },
                    legend: {
                        show: true,
                        top: '22%',
                        textStyle: {
                            fill: '#999999'
                        },
                        data: ['党员', '预备党员', '积极分子']
                    },
                    xAxis: {
                        data: categories,
                        axisLabel: {
                            style: {
                                fill: 'rgba(255,255,255,0.3)',
                                fontSize: 10,
                                rotate: 0
                            }
                        }
                    },
                    yAxis: {
                        data: 'value',
                        splitLine: {
                            show: true,
                            style: {
                                stroke: 'rgba(255,255,255,0.1)',
                                lineWidth: 1
                            }
                        }
                    },
                    grid: {
                        left: '5%',
                        top: '28%',
                        right: '5%',
                        bottom: '20%',
                        style: {
                            fill: 'rgba(255, 255, 255, 0.2)',
                        }
                    },
                    series: [
                        {
                            name: '党员',
                            type: 'bar',
                            data: series0Data,
                            label: this.labelOption,
                            color: '#0458f8'
                        },
                        {
                            name: '预备党员',
                            type: 'bar',
                            data: series1Data,
                            label: this.labelOption,
                            color: '#e3c10c'
                        },
                        {
                            name: '积极分子',
                            type: 'bar',
                            data: series2Data,
                            label: this.labelOption,
                            color: '#11f10a'
                        }
                    ]
                }

                this.setState({
                    option: option
                })

            })
    }

    render() {
        return (
            <BorderBox7>
                <div className="box-title">党支部</div>
                {this.state.option ?  <Charts option={this.state.option}/> : ''}
            </BorderBox7>
        )
    }
}
