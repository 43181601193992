import React, {Component} from "react";
import http from "../../../http";
import moment from "moment";
import {BorderBox7, ScrollBoard} from "@jiaminghi/data-view-react";
import "./atten.scss"

export default class Atten extends Component {
    constructor(props) {
        super(props);

        this.refreshIntervalSeconds = this.$config.refreshIntervalSeconds.manage || 9;

        this.state = {
            recent: null,
            config: null
        }
    }

    componentDidMount() {
        this.timerId && clearInterval(this.timerId)
        this.tick()
        this.timerId = setInterval(() => {
            this.tick()
        }, this.refreshIntervalSeconds * 1000)
    }

    tick() {
        http.get('/api/big_manage/attenRecent')
            .then((res) => {
                if (res.data.result !== 1)
                    return;

                let messages = []
                res.data.recent.recent_messages.forEach((item) => {
                    messages.push([
                        moment(item.time).locale('zh_CN').toNow(true) + '前',
                        item.message
                    ])
                })

                let config = {
                    data: messages,
                    rowNum: 3,
                    evenRowBGC: 'transparent',
                    columnWidth: ['40%', '60%'],
                    align: ['left', 'left']
                }

                this.setState({
                    recent: res.data.recent,
                    config: config
                })
            })
    }

    render() {
        return (
            <BorderBox7>
                <div className="inner-container atten">

                    <div className='detail-list'>
                        <div className='detail-list-item'>
                            <img src='../../assets/images/center-details-data1.png'/>
                            <div className='detail-item-text'>
                                <h3>今日教职工事(病)假</h3>
                                <span>{this.state.recent?.教师请假人数}</span>
                                <span className='unit'>人</span>
                            </div>
                        </div>

                        <div className='detail-list-item'>
                            <img src='../../assets/images/center-details-data6.png'/>
                            <div className='detail-item-text'>
                                <h3>今日学生事(病)假</h3>
                                <span>{this.state.recent?.学生请假人数}</span>
                                <span className='unit'>人</span>
                            </div>
                        </div>
                    </div>

                    <div className="inner-container">
                        {this.state.config ? <ScrollBoard config={this.state.config} className="messages" /> : ''}
                    </div>

                </div>
            </BorderBox7>
        )
    }
}
