import React, {Component} from "react";
import {BorderBox12} from "@jiaminghi/data-view-react";
import "./index.scss"
import AppraisalClass from "./components/class";
import AppraisalTeacher from "./components/teacher";
import AppraisalDepartGrade from "./components/departGrade";
import AppraisalLastRecord from "./components/lastRecord";

export default class Appraisal extends Component {
    render() {
        return (
            <BorderBox12 color={['transparent', 'transparent']} backgroundColor='transparent'>
                <div className="page">
                    <div className="page-title">
                        <i className="iconfont icon-gaikuang"></i>
                        学校考核
                    </div>

                    <div className="page-body page-appraisal">
                        <div className="page-body-left-half">
                            <div className="page-body-left-half-top">
                                <div className="inner-container">
                                    <AppraisalClass />
                                </div>
                            </div>
                            <div className="page-body-left-half-bottom">
                                <div className="inner-container">
                                    <AppraisalTeacher />
                                </div>
                            </div>
                        </div>
                        <div className="page-body-right-half">
                            <div className="page-body-right-half-top">
                                <div className="inner-container">
                                    <AppraisalDepartGrade />
                                </div>
                            </div>
                            <div className="page-body-right-half-bottom">
                                <div className="inner-container">
                                    <AppraisalLastRecord />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BorderBox12>
        )
    }
}
