import React, {Component} from "react";
import {BorderBox7} from "@jiaminghi/data-view-react";
import "./socialActivities.scss"
import http from "../../../http";

export default class SocialActivities extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activities: [],
            left: 0
        }
    }

    componentDidMount() {
        http.get('/api/big_student_quality/socialActivities')
            .then((res) => {
                this.setState({
                    activities: res.data.activities,
                    left: 0
                })

                this.stars_width = res.data.activities.length * 100;
                this.timerId && clearInterval(this.timerId)
                this.timerId = setInterval(() => {
                    let left = this.state.left - 1
                    if (Math.abs(left) > this.stars_width)
                        left = 0

                    this.setState({
                        left: left
                    })
                },50)
            })
    }

    render() {
        let images = this.state.activities.map((activity) => {
            return (<img src={activity} style={{height: '100%'}} />)
        })

        return (
            <BorderBox7>
                <div className="inner-container stars">
                    <div className="photos" style={{left: this.state.left + 'px'}}>
                        {images}
                    </div>
                </div>
            </BorderBox7>
        )
    }
}
