import React, {Component} from "react";
import {BorderBox12} from "@jiaminghi/data-view-react";
import "./index.scss"
import Canteen from "./components/canteen";
import Bedroom from "./components/bedroom";
import Camera from "./components/camera";
import Atten from "./components/atten";

export default class Manage extends Component {
    render() {
        return (
            <BorderBox12 color={['transparent', 'transparent']} backgroundColor='transparent'>
                <div className="page">
                    <div className="page-title">
                        <i className="iconfont icon-gaikuang"></i>
                        学校管理
                    </div>

                    <div className="page-body page-manage">
                        <div className="page-body-left-half">
                            <div className="page-body-left-half-top">
                                <div className="inner-container">
                                    <Bedroom />
                                </div>
                            </div>
                            <div className="page-body-left-half-bottom">
                                <div className="inner-container">
                                    <Camera />
                                </div>
                            </div>
                        </div>
                        <div className="page-body-right-half">
                            <div className="page-body-left-half-top">
                                <div className="inner-container">
                                    <Canteen />
                                </div>
                            </div>
                            <div className="page-body-left-half-bottom">
                                <div className="inner-container">
                                    <Atten />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </BorderBox12>
        )
    }
}
