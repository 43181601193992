import React, {FunctionComponent, Component} from "react";
import {FullScreenContainer} from "@jiaminghi/data-view-react";
import Overview from "./pages/overview";
import StudentQuality from "./pages/studentQuality";
import PartyBuilding from "./pages/partyBuilding";
import SchoolBase from "./pages/schoolBase";
import TeacherGrowth from "./pages/teacherGrowth"
import StudentGrowth from "./pages/studentGrowth";
import Appraisal from "./pages/appraisal";
import Manage from "./pages/manage";
import Title from "./pages/title";
import {useParams} from "react-router-dom";

function Develop() {

    const params = useParams()
    console.log(params);

    return (
        <FullScreenContainer>
            <div className="flex">
                <div className="itemLarge">
                    {params.name.toLowerCase() === "overview" ? <Overview /> : ''}
                    {params.name.toLowerCase() === "appraisal" ? <Appraisal /> : ''}
                    {params.name.toLowerCase() === "schoolbase" ? <SchoolBase /> : ''}
                    {params.name.toLowerCase() === "studentgrowth" ? <StudentGrowth /> : ''}
                    {params.name.toLowerCase() === "studentquality" ? <StudentQuality /> : ''}
                    {params.name.toLowerCase() === "title" ? <Title /> : ''}
                    {params.name.toLowerCase() === "manage" ? <Manage /> : ''}
                    {params.name.toLowerCase() === "teachergrowth" ? <TeacherGrowth /> : ''}
                    {params.name.toLowerCase() === "partybuilding" ? <PartyBuilding /> : ''}
                </div>
            </div>
        </FullScreenContainer>
    )
}

export default Develop
