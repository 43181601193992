// 教师教学成绩
import React, {Component} from "react";
import {BorderBox7} from "@jiaminghi/data-view-react";
import http from "../../../http";
import Charts from "@jiaminghi/data-view-react/es/charts";

export default class ExamTeacherScore extends Component {
    constructor(props) {
        super(props);

        this.refreshIntervalSeconds = this.$config.refreshIntervalSeconds.teacherGrowth || 9

        this.state = {
            title: '',
            countDown: 0,
            option: null
        }
    }

    componentDidMount() {
        http.get('/api/big_teacher_growth/examTeacherScore')
            .then((res) => {
                if (res.data.result === 0)
                    return

                this.grades = res.data.grades
                this.jobs = [];
                this.jobIndex = 0;

                // 一个年级的教师太多，一个图表显示不下
                this.grades.forEach((grade) => {
                    let pages = Math.ceil(grade.teachers.length / 15)
                    for (let page = 0; page < pages; page++) {
                        this.jobs.push({
                            grade: grade,
                            start: page * 15,
                            pageSize: 15
                        })
                    }
                })

                this.setState({ countDown: this.refreshIntervalSeconds })

                this.timerId && clearInterval(this.timerId)
                this.tick()
                this.timerId = setInterval(() => {
                    let nextCountDown = this.state.countDown - 1
                    if (nextCountDown <= 0) {
                        if (this.jobIndex >= this.jobs.length-1)
                            this.jobIndex = 0
                        else
                            this.jobIndex += 1
                        this.tick()
                        nextCountDown = this.refreshIntervalSeconds
                    }

                    this.setState({  countDown: nextCountDown })

                }, 1000)

            })
    }

    tick() {
        let job = this.jobs[this.jobIndex]
        let categories = []
        let series0Data = []
        for (let i=job.start; i<Math.min(job.grade.teachers.length, job.start+job.pageSize); i++) {
            categories.push(job.grade.teachers[i].teacherName)
            series0Data.push(job.grade.teachers[i].score)
        }

        let option = {
            title: {
                show: false
            },
            legend: {
                show: false
            },
            xAxis: {
                data: categories,
                axisLabel: {
                    style: {
                        fill: 'rgba(255,255,255,0.3)',
                        fontSize: 10,
                        rotate: 45
                    }
                }
            },
            yAxis: [
                {
                    name: '教学成绩',
                    data: 'value',
                    min: Math.min(...series0Data),
                    max: Math.max(...series0Data),
                    splitLine: {
                        show: true,
                        style: {
                            stroke: 'rgba(255,255,255,0.1)',
                            lineWidth: 1
                        }
                    }
                }
            ],
            grid: {
                left: '5%',
                top: '18%',
                right: '5%',
                bottom: '20%',
            },
            series: [
                {
                    name: '教学成绩',
                    data: series0Data,
                    type: 'line',
                    labelOption: {
                        show: true,
                        style: {
                            fill: '#e79205'
                        }
                    },
                    lineArea: {
                        show: true,
                        gradient: ['rgba(251, 114, 147, 1)', 'rgba(251, 114, 147, 0)']
                    },
                    lineStyle: {
                        stroke: 'rgba(251, 114, 147, 1)',
                        lineDash: [3, 3]
                    },
                    linePoint: {
                        style: {
                            stroke: 'rgba(251, 114, 147, 1)'
                        }
                    },
                }
            ]
        }

        this.setState({
            title: job.grade.gradeName,
            option: option
        })
    }

    render() {
        return (
            <BorderBox7>
                <div className="box-title">教学成绩考核分({this.state.title}) ({this.state.countDown}秒后切换...)</div>
                {this.state.option ?
                    <Charts option={this.state.option}
                            style={{height: '100%', width: '100%'}} />
                    : '' }
            </BorderBox7>
        )
    }
}
