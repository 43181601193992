import './App.scss';
import React from "react";
import {HashRouter, Route, Routes} from "react-router-dom";
import Develop from "./develop";
import Production from "./production";
import Overview from "./pages/overview";

function App() {
    return (
        <HashRouter>
            <Routes>
                <Route path="/develop/:name" element={<Develop/>}/>
                <Route path="/" element={<Production/>}/>
            </Routes>
        </HashRouter>
    );
}

export default App;
