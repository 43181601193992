import React, {Component} from "react";
import {BorderBox7, ScrollBoard} from "@jiaminghi/data-view-react";
import http from "../../../http";
import moment from "moment";
import "moment/locale/zh-cn"

export default class AppraisalLastRecord extends Component {
	constructor(props) {
		super(props);

		this.refreshIntervalSeconds = this.$config.refreshIntervalSeconds.appraisal || 9;

		this.state = {
			scrollBoardConfig: {
				data: []
			}
		}
	}

	fetchData() {
		http.get('/api/big_appraisal/recentMessages')
			.then((res) => {
				if (res.data.result !== 1)
					return;

				let data = []
				res.data.messages.forEach((item) => {
					let time = moment(item.time).locale('zh-cn').toNow(true) + '前'
					data.push([time, item.message])
				})

				this.setState({
					scrollBoardConfig: {
						data: data,
						rowNum: 5,
						evenRowBGC: 'transparent',
						columnWidth: ["40%", "60%"],
						align: ['left', 'left']
					}
				})
			})
	}

	componentDidMount() {
		this.fetchData()
		this.timerId && clearInterval(this.timerId)
		this.timerId = setInterval(() => {
			this.fetchData()
		}, this.refreshIntervalSeconds * 1000)
	}

	componentWillUnmount() {
		this.timerId && clearInterval(this.timerId)
	}

	render() {
		return (
			<BorderBox7>
				<div className="inner-container" style={{overflow:"hidden"}}>
					<div style={{width:"100%", height:"2rem"}}>
						<div className="box-title">最新考核记录</div>
					</div>
					<div className="inner-container">
						<ScrollBoard config={this.state.scrollBoardConfig}/>
					</div>
				</div>
			</BorderBox7>
		)
	}
}
