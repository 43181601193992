import React, {Component} from "react";
import {BorderBox12} from "@jiaminghi/data-view-react";
import "./index.scss"
import Activities from "./components/activities";
import InterestClasses from "./components/interestClasses";
import PoliticalMicroClassrooms from "./components/politicalMicroClassrooms";
import MicroVideo from "./components/microVideo";

export default class SchoolBase extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <BorderBox12 color={['transparent', 'transparent']} backgroundColor='transparent'>
                <div className="page">

                    <div className="page-title">
                        <i className="iconfont icon-gaikuang"></i>
                        立德树人
                    </div>

                    <div className="page-body page-overview">
                        <div className="page-body-left">
                            <div className="page-body-left-top">
                                <div className="inner-container">
                                    <InterestClasses />
                                </div>
                            </div>
                            <div className="page-body-left-bottom">
                                <div className="inner-container">
                                    <MicroVideo  />
                                </div>
                            </div>
                        </div>
                        <div className="page-body-right">
                            <div className="inner-container">
                                <Activities/>
                            </div>
                        </div>
                    </div>

                </div>
            </BorderBox12>
        )
    }
}
