import React, {Component} from "react";
import {BorderBox7, Charts} from "@jiaminghi/data-view-react";
import http from "../../../http";

export default class AppraisalTeacher extends Component {
    constructor(props) {
        super(props);

        this.refreshIntervalSeconds = this.$config.refreshIntervalSeconds.appraisal || 9;

        this.state = {
            option: null
        }
    }

    makeOption(categories, series_data) {
        return {
            title: {
                show: false
            },
            legend: {
                show: false
            },
            xAxis: {
                data: categories,
                axisLabel: {
                    style: {
                        fill: 'rgba(255,255,255,0.3)',
                        fontSize: 10,
                        rotate: 45
                    }
                }
            },
            yAxis: {
                data: 'value',
                splitLine: {
                    show: true,
                    style: {
                        stroke: 'rgba(255,255,255,0.1)',
                        lineWidth: 1
                    }
                }
            },
            grid: {
                left: '5%',
                top: '18%',
                right: '5%',
                bottom: '20%',
                style: {
                    fill: 'rgba(255, 255, 255, 0.2)',
                }
            },
            series: [
                {
                    data: series_data,
                    type: 'bar',
                    gradient: {
                        color: ['#67e0e3', '#37a2da']
                    },
                    label: {
                        show: true,
                        style: {
                            fill: '#e79205'
                        }
                    }
                }
            ]
        }
    }

    tick() {
        http.get('/api/big_appraisal/teacher')
            .then((res) => {

                let categories = []
                let series_data = []
                res.data.departments.forEach((item) => {
                    if (item.score === 0)
                        return
                    categories.push(item.name)
                    series_data.push(item.score)
                })

                this.setState({
                    option: this.makeOption(categories, series_data)
                })
            })
    }

    componentDidMount() {
        this.timerId && clearInterval(this.timerId)
        this.tick()
        this.timerId = setInterval(() => {

            this.tick()

        }, this.refreshIntervalSeconds * 1000);
    }

    componentWillUnmount() {
        this.timerId && clearInterval(this.timerId)
    }

    render() {
        return (
            <BorderBox7>
                <div className="box-title">教师考核【加扣分】</div>
                <Charts option={this.state.option}/>
            </BorderBox7>
        )
    }
}
