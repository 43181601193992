import React, {Component} from "react";
import http from "../../../http";

export default class BigNumbers extends Component {
    constructor(props) {
        super(props);

        this.refreshIntervalSeconds = this.$config.refreshIntervalSeconds.teacherGrowth || 9

        this.state = {
            count: null
        }
    }

    tick() {
        http.get('/api/big_teacher_growth/count')
            .then((res) => {
                if (res.data.result !== 1)
                    return
                this.setState({
                    count: res.data.count
                })
            })
    }

    componentDidMount() {
        this.tick()
        this.timerId && clearInterval(this.timerId)
        this.timerId = setInterval(() => {
            this.tick()
        }, this.refreshIntervalSeconds * 1000)
    }

    render() {
        return (
            <div className='detail-list'>
                <div className='detail-list-item'>
                    <img src='../../assets/images/center-details-data1.png'/>
                    <div className='detail-item-text'>
                        <h3>校本资源</h3>
                        <span>{this.state.count?.resources}</span>
                        <span className='unit'>条</span>
                    </div>
                </div>

                <div className='detail-list-item'>
                    <img src='../../assets/images/center-details-data2.png'/>
                    <div className='detail-item-text'>
                        <h3 style={{fontSize: '0.8rem'}}>今日有效性评价</h3>
                        <span>{this.state.count?.today_learning_valids}</span>
                        <span className='unit'>人/次</span>
                    </div>
                </div>

                <div className='detail-list-item'>
                    <img src='../../assets/images/center-details-data3.png'/>
                    <div className='detail-item-text'>
                        <h3>直录播课堂</h3>
                        <span>{this.state.count?.vod_lives}</span>
                        <span className='unit'>堂</span>
                    </div>
                </div>

                <div className='detail-list-item'>
                    <img src='../../assets/images/center-details-data4.png'/>
                    <div className='detail-item-text'>
                        <h3>在线作业</h3>
                        <span>{this.state.count?.homework_students}</span>
                        <span className='unit'>人/次</span>
                    </div>
                </div>
            </div>
        )
    }
}
