import React, {Component} from "react";
import {BorderBox12} from "@jiaminghi/data-view-react";
import "./index.scss"
import TeacherLearningValid from "./components/teacherLearningValid";
import ExamTeacherScore from "./components/examTeacherScore";
import BigNumbers from "./components/bigNumbers";
import Stars from "./components/stars";

export default class Manage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: null
        }
    }

    render() {
        return (
            <BorderBox12 color={['transparent', 'transparent']} backgroundColor='transparent'>
                <div className="page">
                    <div className="page-title">
                        <i className="iconfont icon-gaikuang"></i>
                        教师发展
                    </div>

                    <div className="page-body page-teacher-growth">
                        <div className="page-body-left-half">
                            <div className="page-body-left-half-top">
                                <div className="inner-container">
                                    <BigNumbers />
                                </div>
                            </div>
                            <div className="page-body-left-half-bottom">
                                <div className="inner-container">
                                    <Stars />
                                </div>
                            </div>
                        </div>
                        <div className="page-body-right-half">
                            <div className="page-body-left-half-top">
                                <div className="inner-container">
                                    <ExamTeacherScore />
                                </div>
                            </div>
                            <div className="page-body-left-half-bottom">
                                <div className="inner-container">
                                    <TeacherLearningValid />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </BorderBox12>
        )
    }
}
