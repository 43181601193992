import React, {Component} from "react";
import {FullScreenContainer} from "@jiaminghi/data-view-react";
import Overview from "./pages/overview";
import Title from "./pages/title";
import PartyBuilding from "./pages/partyBuilding"
import Manage from "./pages/manage";
import SchoolBase from "./pages/schoolBase"
import Appraisal from "./pages/appraisal";
import StudentGrowth from "./pages/studentGrowth";
import TeacherGrowth from "./pages/teacherGrowth"
import StudentQuality from "./pages/studentQuality";

export default class Production extends Component {
    handleResize = e => {
        window.location.reload();
        setTimeout(() => {
            window.location.reload();
        }, 10000);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize.bind(this));
    }

    render() {
        return (
            <FullScreenContainer>
                <div className="flex">
                    <div className="item">
                        <Overview />
                    </div>
                    <div className="item">
                        <Title />
                    </div>
                    <div className="item">
                        <PartyBuilding />
                    </div>


                    <div className="item">
                        <Manage />
                    </div>
                    <div className="item">
                        <StudentQuality />
                    </div>
                    <div className="item">
                        <SchoolBase />
                    </div>


                    <div className="item">
                        <Appraisal />
                    </div>
                    <div className="item">
                        <StudentGrowth />
                    </div>
                    <div className="item">
                        <TeacherGrowth />
                    </div>
                </div>
            </FullScreenContainer>
        )
    }
}
